import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, List, Paper, Typography } from '@material-ui/core';

import InfoWithPopover from '@/components/info-with-popover';
import Loading from '@/components/loading';

import UserRankingItem from '../../components/user-ranking-item';
import UserTopRankingItem from '../../components/user-top-ranking-item';

import { useGetDuelsRankingQuery } from '@/graphql';

const Ranking = () => {
  const { t } = useTranslation();

  const { data, loading } = useGetDuelsRankingQuery({
    fetchPolicy: 'cache-and-network',
  });
  const duelsRankingLength = useMemo(() => data?.ranking?.length || 0, [data]);

  const rankingList = useMemo(
    () =>
      data?.ranking &&
      data?.ranking.map(
        (score, i) =>
          score && (
            <UserRankingItem
              data-cy-user-ranking-item
              key={score?.id}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              score={score as any}
              divider={i !== duelsRankingLength - 1}
            />
          )
      ),
    [data, duelsRankingLength]
  );
  const isEmpty = useMemo(() => !data?.topUsers?.length, [data]);

  if (loading && !data) return <Loading />;

  return !isEmpty ? (
    <Box data-cy-ranking>
      <Paper square elevation={0}>
        <Box paddingX={4} paddingY={5}>
          <Box marginBottom={3}>
            <Grid container>
              <Grid xs item>
                <Typography variant="overline">
                  {t('duels.top-users')}
                </Typography>
              </Grid>
              <Grid item>
                <InfoWithPopover text={t('duels.ranking.help-text')} />
              </Grid>
            </Grid>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr 1fr"
            gridColumnGap={8}
          >
            {data?.topUsers?.map((it, i) => (
              <UserTopRankingItem
                data-cy-top-user-ranking-item
                key={`top-user-ranking-${i}`}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                score={it as any}
              />
            ))}
          </Box>
        </Box>
      </Paper>
      <Box padding={2}>
        <Paper elevation={0}>
          {!!duelsRankingLength && <List>{rankingList}</List>}
        </Paper>
      </Box>
    </Box>
  ) : (
    <Box clone data-cy-ranking height="100%">
      <Paper square elevation={0}>
        <Box display="flex" justifyContent="center" paddingY={10}>
          <Typography color="textSecondary" variant="caption">
            {t('duels.ranking.empty')}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default Ranking;
