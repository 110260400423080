import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { Box, Paper } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  ActualLearnWeekWeeklyGoal,
  useDailyTipperDataQuery,
  useDtWeeklyProgressQuery,
} from '@/graphql';
import EmptyDailyTipper from '../../components/empty-daily-tipper';
import DailyTipperCard from '../../components/daily-tipper-card';
import GoalCard from './goal-card';

const LearningWeek: React.FC = () => {
  const { data, loading } = useDtWeeklyProgressQuery();
  const dailyTipperWeeklyProgress = useMemo(
    () => data?.dailyTipperWeeklyProgress,
    [data]
  );
  const { error: dtDataError } = useDailyTipperDataQuery();

  const weeklyGoalNumber = useMemo(() => {
    switch (dailyTipperWeeklyProgress?.weeklyGoal) {
      case ActualLearnWeekWeeklyGoal.A_4:
        return 4;
      case ActualLearnWeekWeeklyGoal.A_5:
        return 5;
      case ActualLearnWeekWeeklyGoal.A_6:
        return 6;
      case ActualLearnWeekWeeklyGoal.A_7:
        return 7;
      default:
        return undefined;
    }
  }, [dailyTipperWeeklyProgress]);

  if (loading && !data)
    return (
      <Paper elevation={0}>
        <Box py={2} px={2}>
          {[...Array(9)].map((i, idx) => (
            <Skeleton key={idx} height={30} />
          ))}
        </Box>
      </Paper>
    );

  return data?.me?.dailytipper === null || !!dtDataError ? (
    <Box
      px={2}
      pt={5}
      data-cy-empty-ongoind-duels
      clone
      flexGrow={1}
      display="flex"
    >
      <Paper elevation={0}>
        <EmptyDailyTipper isDtPage />
      </Paper>
    </Box>
  ) : (
    <>
      {dailyTipperWeeklyProgress ? (
        <Box p={2}>
          <GoalCard goal={weeklyGoalNumber} />
          <DailyTipperCard
            sucessCount={dailyTipperWeeklyProgress?.correctAnswersCount}
            errorCount={dailyTipperWeeklyProgress?.wrongAnswersCount}
            count={
              dailyTipperWeeklyProgress.weekNumber !==
              DateTime.local().weekNumber
                ? 0
                : dailyTipperWeeklyProgress?.completedDays
            }
            all={weeklyGoalNumber}
          />
        </Box>
      ) : (
        <Box p={2} pt={5} clone flexGrow={1} display="flex">
          <Paper elevation={0}>
            <EmptyDailyTipper disabled={false} isDtPage />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default LearningWeek;
