import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { Box, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useClassRoomsOngoingQuery, useGetActiveDuelsQuery } from '@/graphql';
import ClassroomCard from '../card';
import ClassroomCardWrap from '../card-wrap';
import EmptyHomepageClassrooms from '../../components/empty-homepage-classrooms ';
import { MAIN_ORGANIZER_NAME } from '@/utils/constants';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { useStyles } from './styles';
import { ClassroomTypes } from '@/type';

const MyLearningHomepage: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isUserPremium } = useGetPremiumData();

  const { data, loading } = useClassRoomsOngoingQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: activeDuelsData } = useGetActiveDuelsQuery();

  return loading && !data ? (
    <Paper elevation={0}>
      <Box p={4}>
        {[...Array(7)].map((i, idx) => (
          <Skeleton key={idx} height={25} />
        ))}
      </Box>
    </Paper>
  ) : data?.ongoingClassRooms?.length ? (
    <Paper elevation={0}>
      <Box px={6} py={5} className={classes.header} id="my-learning-card-icon">
        <Typography variant="h2">{t('class-room.title2')}</Typography>
      </Box>
      <Box pb={5}>
        {data?.ongoingClassRooms?.map(
          (it, idx) =>
            it?.learningSession && (
              <ClassroomCardWrap
                key={it.learningSession?.id}
                sessionId={it.learningSession.id}
                finishDate={
                  it.networkStartDate
                    ? DateTime.fromISO(it.networkStartDate).plus({
                        days: (it.learningSession.duration || 1) - 1,
                      })
                    : it.learningSession?.finishDate
                }
                classRoomType={it.learningSession?.classRoom?.enrollmentType}
                sessionStatus={it.learningSession?.status}
                activeDuelsLength={
                  it?.learningSession.classRoom.enrollmentType ===
                    ClassroomTypes.BLENDED || isUserPremium
                    ? activeDuelsData?.activeDuels?.length || 0
                    : activeDuelsData?.startedDuelsToday || 0
                }
              >
                {idx !== 0 && <div className={classes.divider} />}
                <ClassroomCard
                  id={it.learningSession.id}
                  link={`/classroom/${it.learningSession.id}`}
                  title={it.learningSession.classRoom.title}
                  organizerName={it.learningSession?.classRoom?.organizer?.name}
                  startDate={
                    it.networkStartDate || it.learningSession?.startDate
                  }
                  finishDate={
                    it.networkStartDate
                      ? DateTime.fromISO(it.networkStartDate).plus({
                          days: (it.learningSession.duration || 1) - 1,
                        })
                      : it.learningSession?.finishDate
                  }
                  classRoomImage={
                    (it.learningSession?.classRoom?.organizer?.name ===
                      MAIN_ORGANIZER_NAME &&
                      it.learningSession?.classRoom?.backgroundImage) ||
                    it.learningSession?.classRoom?.classRoomImage
                  }
                  specialityGroupIcon={
                    it.learningSession?.classRoom?.organizer?.name ===
                    MAIN_ORGANIZER_NAME
                      ? it.specialityGroupIcon
                      : null
                  }
                  classRoomType={it.learningSession?.classRoom?.enrollmentType}
                  // averageRate={
                  //   it.learningSession.classRoom?.aggregatedRate
                  //     ?.averageRate
                  // }
                  // feedbackCount={
                  //   it.learningSession.classRoom?.aggregatedRate
                  //     ?.feedbackCount
                  // }
                  certificate={
                    it.learningSession?.classRoom?.certificate?.points
                  }
                  isForPremiumOnly={it.learningSession?.isForPremiumOnly}
                  indicator={!!it.isSessionActive}
                  correctAnswersGoal={it.correctAnswersGoal}
                  questionsAmount={it.questionsAmount}
                  correctAnswersAmount={it.correctAnswersAmount}
                  isOngoing
                  isOnHomepage
                />
              </ClassroomCardWrap>
            )
        )}
      </Box>
    </Paper>
  ) : (
    <EmptyHomepageClassrooms />
  );
};

export default MyLearningHomepage;
