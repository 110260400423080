import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { ReactComponent as OfflineIcon } from './index.svg';
import theme from '@/theme';
import useOnlineStatus from '@/hooks/useOnlineStatus';

const Offline: React.FC = (props) => {
  const { t } = useTranslation();
  const isOnline = useOnlineStatus();

  return !isOnline ? (
    <Box
      paddingY={7}
      paddingX={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      boxSizing="border-box"
      style={{ backgroundColor: theme.palette.common.white }}
    >
      <Box marginBottom={3}>
        <OfflineIcon />
      </Box>
      <Box marginBottom={1}>
        <Typography variant="subtitle1">
          {t('offline.message.title')}
        </Typography>
      </Box>
      <Box marginBottom={5} marginX={8}>
        <Typography align="center" variant="body2">
          {t('offline.message.info')}
        </Typography>
      </Box>
    </Box>
  ) : (
    <>{props.children}</>
  );
};

export default Offline;
