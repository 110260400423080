import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Typography, IconButton, Button, Link } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Skeleton } from '@material-ui/lab';
import { useQuizTimeDetailsQuery } from '@/graphql';
import BoxMagicIcon from '@/components/icons/box-magic';
import DuelRule1Icon from '@/components/icons/duel-rule-1';
import DuelRule2Icon from '@/components/icons/duel-rule-2';
import DuelRule3Icon from '@/components/icons/duel-rule-3';
import DuelRule5Icon from '@/components/icons/duel-rule-5';
import DuelRule7Icon from '@/components/icons/duel-rule-7';
import { useStyles } from './styles';
import DuelRule8Icon from '@/components/icons/duel-rule-8';

type QuizTimeParams = {
  quizSessionId: string;
};

const QuizTimeRules: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { quizSessionId } = useParams<QuizTimeParams>();

  const { data, loading } = useQuizTimeDetailsQuery({
    variables: { quizSessionId },
  });

  const participationConditions =
    data?.quizTimeSession?.quizTime?.participationConditions;

  const dataArr = [
    { key: '1', icon: <DuelRule8Icon /> },
    { key: '2', icon: <DuelRule1Icon /> },
    { key: '3', icon: <DuelRule2Icon /> },
    { key: '4', icon: <DuelRule3Icon /> },
    { key: '5', icon: <DuelRule5Icon /> },
    { key: '6', icon: <DuelRule7Icon /> },
    { key: '7', icon: <BoxMagicIcon /> },
  ];

  const closePage = () => history.replace(`/quiztime-details/${quizSessionId}`);

  return (
    <Box className={classes.root}>
      <IconButton
        aria-label="close"
        className={classes.close}
        onClick={closePage}
      >
        <CloseIcon htmlColor="#4A647C" />
      </IconButton>
      <Typography variant="h2">
        {t('quiztime.rules-page.header-title')}
      </Typography>
      <Box className={classes.headerInfo}>
        <Typography variant="subtitle2">
          {t('quiztime.rules-page.header-info')}
        </Typography>
      </Box>
      <Box mt={5} mb={7}>
        {loading && !data ? (
          <Box>
            {[...Array(15)].map((i, idx) => (
              <Skeleton key={idx} height={30} />
            ))}
          </Box>
        ) : (
          dataArr.map((it, idx) => (
            <Box key={it.key} mt={2} className={classes.listItem}>
              <Box className={classes.iconBox}>{it.icon}</Box>
              <Box>
                <Typography variant="body2" color="textPrimary">
                  {t(`quiztime.rules-page.rule-${it.key}.caption`)}
                  {dataArr.length === idx + 1 && participationConditions && (
                    <Link
                      href={participationConditions}
                      target="_blank"
                      color="primary"
                    >
                      {t('quiztime.rules.terms-of-participation.title')}
                    </Link>
                  )}
                </Typography>
              </Box>
            </Box>
          ))
        )}
      </Box>
      <Button fullWidth color="primary" variant="contained" onClick={closePage}>
        {t('common.ok')}
      </Button>
    </Box>
  );
};

export default QuizTimeRules;
