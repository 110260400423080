import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Box, IconButton, Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import СupIcon from '@/components/icons/cup';
import { useStyles } from './styles';
import {
  useDailyTipperDataQuery,
  useDtWeeklyProgressQuery,
  useUserMeQuery,
} from '@/graphql';
import { Skeleton } from '@material-ui/lab';

type GoalsInfoProps = {
  hideLinkArrow?: boolean | undefined;
};

const GoalsInfo: React.FC<GoalsInfoProps> = ({ hideLinkArrow }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data: userMeData } = useUserMeQuery();
  const { data, loading } = useDtWeeklyProgressQuery();
  const { error: dtDataError } = useDailyTipperDataQuery();

  const dailytipper = data?.me?.dailytipper;
  const nextAchievement = dailytipper?.nextAchievement?.learningDays ?? 0;
  const currentAchievement = dailytipper?.currentAchievement?.learningDays ?? 0;

  return (
    <div className={classes.root}>
      {loading && !data ? (
        <Box px={2} py={2}>
          {[...Array(3)].map((i, idx) => (
            <Skeleton key={idx} height={30} />
          ))}
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            px={6}
            pr={2}
            py={4}
            style={{ borderBottom: '1px solid #E6EEFA' }}
          >
            <Box display="flex" alignItems="center">
              {currentAchievement > 0 && (
                <Box mr={5} className={classes.cupIconBox}>
                  <СupIcon className={classes.cupIcon} color="primary" />
                </Box>
              )}
              <Box>
                <Typography variant="overline">
                  {t('daily-tipper.your-achievement')}
                </Typography>
                <Typography variant="subtitle1">
                  {t('daily-tipper.learning-days', {
                    value: currentAchievement,
                  })}
                </Typography>
              </Box>
            </Box>
            {!hideLinkArrow && (
              <IconButton
                color="primary"
                component={RouterLink}
                size="small"
                to="/daily-tipper/achievements"
                style={{ padding: 0 }}
                data-dd-action-name="DT navigate to achievements"
              >
                <ChevronRight style={{ color: '#879BB2' }} />
              </IconButton>
            )}
          </Box>
          {!!userMeData?.me?.settings?.dailyTipperMode && !dtDataError && (
            <Box textAlign="center" px={8} py={2.5}>
              <Typography variant="caption">
                {t('daily-tipper.next-achievement', { value: nextAchievement })}
              </Typography>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default GoalsInfo;
