import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import Create from './create';
import PersonalInfo from './personal-info';
import ChooseProfession from './choose-profession';
import ProfessionInfo from './profession-info';
import Completed from './completed';
import { SS_KEY_STEP_SIGNUP } from '@/utils/constants';
import { useSignUp } from '@/contexts/sign-up-context';

const SignUp: React.FC = () => {
  const { update } = useSignUp();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const professionType = useMemo(() => queryParams.get('professionType'), [queryParams]);
  useEffect(() => {
    if (professionType) {
      update({ professionType: +professionType });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [professionType]);

  const jsonStepData = sessionStorage.getItem(SS_KEY_STEP_SIGNUP);
  const initialStepData = jsonStepData ? JSON.parse(jsonStepData) : 1;
  const [currentStep, setStep] = useState<number>(initialStepData);
  const [stepStartTime, setStepStartTime] = useState<number>(Date.now());

  useEffect(() => {
    datadogRum.startSessionReplayRecording();
  }, []);

  // Track when the step starts
  const handleStepStart = useCallback((step: number) => {
    setStepStartTime(Date.now());
    datadogRum.addAction(`sign_up_step${step}_start`, {
      step,
      timestamp: Date.now(),
    });
  }, []);

  // Track when the step ends
  const handleStepEnd = useCallback(
    (step: number) => {
      const timeSpent = Date.now() - stepStartTime;
      datadogRum.addAction('sign_up_step_time_spent', {
        step,
        timeSpent,
        timestamp: Date.now(),
      });
    },
    [stepStartTime]
  );

  // Go to the next step
  const next = useCallback(() => {
    handleStepEnd(currentStep);

    setStep((prev) => {
      let nextStep = prev + 1;
      // Skip step 3 if professionType is '1' or '5'
      if (nextStep === 3 && (professionType === '1' || professionType === '5')) {
        nextStep += 1;
      }
      handleStepStart(nextStep);
      return nextStep;
    });
  }, [currentStep, handleStepEnd, handleStepStart, professionType]);

  // Go to the previous step
  const back = useCallback(() => {
    handleStepEnd(currentStep);

    setStep((prev) => {
      let previousStep = prev - 1;
      // Skip step 3 if professionType is '1' or '5'
      if (previousStep === 3 && (professionType === '1' || professionType === '5')) {
        previousStep -= 1;
      }
      handleStepStart(previousStep);
      datadogRum.addAction('sign_up_step_back', {
        fromStep: currentStep,
        toStep: previousStep,
      });
      return previousStep;
    });
  }, [currentStep, handleStepEnd, handleStepStart, professionType]);

  useEffect(() => {
    if (!jsonStepData) {
      handleStepStart(1);
    }
    sessionStorage.setItem(SS_KEY_STEP_SIGNUP, JSON.stringify(currentStep));
  }, [currentStep, handleStepStart, jsonStepData]);

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Create onNext={next} onBack={back} />;
      case 2:
        return <PersonalInfo onNext={next} onBack={back} />;
      case 3:
        return <ChooseProfession onNext={next} onBack={back} />;
      case 4:
        return <ProfessionInfo onNext={next} onBack={back} />;
      case 5:
        return <Completed />;
      default:
        return <></>;
    }
  };

  return renderStep();
};

export default SignUp;