import React, { Ref } from 'react';

import {
  Box,
  FormControl,
  RadioGroup,
  FormLabel,
  RadioGroupProps,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

interface RadioFieldProps extends RadioGroupProps {
  fullWidth?: boolean;
  label?: string;
  options: { label: string; value: string }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputRef?: Ref<any>;
  disabled?: boolean;
}

const RadioField: React.FC<RadioFieldProps> = ({
  name,
  options,
  fullWidth,
  label,
  disabled,
  inputRef,
  ...rest
}) => (
  <FormControl component="fieldset" disabled={disabled} fullWidth={fullWidth}>
    {label && (
      <Box marginBottom={2}>
        <FormLabel component="legend">{label}</FormLabel>
      </Box>
    )}
    <RadioGroup color="primary" {...rest}>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio color="primary" />}
          label={option.label}
          name={name}
          inputRef={inputRef}
        />
      ))}
    </RadioGroup>
  </FormControl>
);

export default RadioField;
