import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import { Box, List, ListItem, Typography } from '@material-ui/core';

import { useUser } from '@/contexts/user-context';
import {
  DuelRoundType,
  SubscribeNotificationCreatedDocument,
  useUserDuelQuery,
} from '@/graphql';
import Dot from '@/components/dot';
import CheckIcon from '@/components/icons/check';
import { Skeleton } from '@material-ui/lab';

interface OverviewRoundsListProps {
  maxRoundsCount: number;
  duelIdOut?: string;
}

const OverviewRoundsList: React.FC<OverviewRoundsListProps> = ({
  maxRoundsCount,
  duelIdOut,
}) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const { duelId } = useParams<{ duelId: string }>();

  const { data, subscribeToMore, refetch } = useUserDuelQuery({
    variables: { duelId: duelIdOut || duelId },
    fetchPolicy: 'cache-and-network',
  });
  subscribeToMore({
    document: SubscribeNotificationCreatedDocument,
    updateQuery: (prev) => {
      refetch();
      return prev;
    },
  });

  const rederDotsFn = (question: {
    id: string;
    userAnswer?: { value: string } | null;
    correctAnswer?: { value: string } | null;
  }) => {
    if (!question.userAnswer || !question.correctAnswer)
      return <Dot key={question.id} variant="default" size="large" />;
    return question.userAnswer?.value === question.correctAnswer?.value ? (
      <Dot key={question.id} variant="success" size="large">
        <Box
          component="span"
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', height: '100%' }}
        >
          <CheckIcon style={{ width: 12, height: 12, color: '#fff' }} />
        </Box>
      </Dot>
    ) : (
      <Dot key={question.id} variant="error" size="large" />
    );
  };

  const rounds = useMemo(
    () => (!!data?.duelData?.rounds ? data?.duelData?.rounds : []),
    [data]
  );
  const activeRoundNumber = rounds.length;

  const isAllFilled = useMemo(
    () =>
      !!data?.duelData?.rounds &&
      data.duelData.rounds[activeRoundNumber - 1]?.userQuestions.every(
        (it) => !!it.userAnswer
      ),
    [activeRoundNumber, data]
  );

  const renderRound = useMemo(() => {
    const roundCount = rounds.length;
    const restround = Array.from(
      { length: maxRoundsCount - roundCount },
      (_, i) =>
        ({
          id: `round-${i}`,
          created: '',
          updated: '',
          statusIgnored: false,
          roundNumber: roundCount + i + 1,
          userQuestions: [],
          duel: null,
        } as DuelRoundType)
    );

    const allround = data ? [...rounds, ...restround] : [];
    return allround.map((round, idx) => {
      if (!round) return <></>;
      const isYourTurn =
        data?.duelData?.isOnboardingDuel || data?.duelData?.userScore?.youTurn;
      const roundQuestions = round.userQuestions;
      const isYourFilledAny = roundQuestions
        .filter((it) => it.user?.id === user?.id)
        .some((it) => !!it.userAnswer);
      const isOpponentFilledAny = roundQuestions
        .filter((it) => it.user?.id !== user?.id)
        .some((it) => !!it.userAnswer);
      const isRoundFinished =
        activeRoundNumber - 1 > idx ||
        (activeRoundNumber - 1 === idx && isAllFilled);
      const isUserDotsHighlightedOnPrestart =
        !!data?.duelData?.userScore?.initiator &&
        !isYourFilledAny &&
        activeRoundNumber === 1;
      const isUserDotsHighlighted =
        isUserDotsHighlightedOnPrestart ||
        (isYourTurn && idx === activeRoundNumber && isAllFilled) ||
        (isYourTurn && idx === activeRoundNumber - 1 && !isYourFilledAny);
      const userDots =
        !roundQuestions.length || isUserDotsHighlighted
          ? [1, 2, 3].map((it) => (
              <Dot
                key={it}
                variant={
                  (idx === activeRoundNumber - 1 &&
                    isUserDotsHighlightedOnPrestart) ||
                  (roundQuestions.length === 0 && idx === 0) ||
                  (isYourTurn && idx === activeRoundNumber && isAllFilled) ||
                  (isYourTurn &&
                    idx === activeRoundNumber - 1 &&
                    !isYourFilledAny)
                    ? 'highlighted'
                    : 'default'
                }
                size="large"
              />
            ))
          : roundQuestions
              .filter((it) => it.user?.id === user?.id)
              .map(rederDotsFn);

      const isOpponentDotsHighlighted =
        !isUserDotsHighlightedOnPrestart &&
        ((!isYourTurn && idx === activeRoundNumber && isAllFilled) ||
          (!isYourTurn &&
            idx === activeRoundNumber - 1 &&
            !isOpponentFilledAny));
      const opponentDots =
        !roundQuestions.length || isOpponentDotsHighlighted
          ? [1, 2, 3].map((it) => (
              <Dot
                key={it}
                variant={isOpponentDotsHighlighted ? 'highlighted' : 'default'}
                size="large"
              />
            ))
          : sortBy(roundQuestions, 'correctAnswer')
              .filter((it) => it.user?.id !== user?.id)
              .map(rederDotsFn);

      return (
        <Box clone key={`roudn-${idx}`}>
          <ListItem divider={idx < allround.length}>
            <Box data-cy-rouds width="100%" py={4}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="grid"
                  gridTemplateColumns="auto auto auto"
                  gridColumnGap={10}
                  paddingX={6}
                >
                  {userDots}
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexShrink={0}
                  px={2.5}
                  py={2}
                  style={{
                    minWidth: 72,
                    textAlign: 'center',
                    border: `1px solid #${
                      isRoundFinished
                        ? 'B6E4FA'
                        : activeRoundNumber - 1 === idx || idx === 0
                        ? '879BB2'
                        : 'E6EEFA'
                    }`,
                    borderRadius: 4,
                    backgroundColor: isRoundFinished ? '#E2F5FD' : '',
                    boxSizing: 'border-box',
                  }}
                >
                  <Typography
                    variant="overline"
                    style={{ color: isRoundFinished ? '#2B75B3' : 'initial' }}
                  >
                    {maxRoundsCount > 1
                      ? t('common.round-number', { round: round.roundNumber })
                      : t('common.round')}
                  </Typography>
                </Box>

                <Box
                  display="grid"
                  gridTemplateColumns="auto auto auto"
                  gridColumnGap={10}
                  paddingX={6}
                >
                  {opponentDots}
                </Box>
              </Box>
            </Box>
          </ListItem>
        </Box>
      );
    });
  }, [activeRoundNumber, data, rounds, t, user, isAllFilled, maxRoundsCount]);

  return !data?.duelData ? (
    <Box p={4}>
      {[...Array(6)].map((i, idx) => (
        <Skeleton key={idx} height={30} />
      ))}
    </Box>
  ) : (
    <div>
      <List>{renderRound}</List>
    </div>
  );
};

export default OverviewRoundsList;
