import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, List, Paper, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import {
  QuizTimeSessionStatus,
  QuizTimeUsersDocument,
  useGetActiveQuizDuelsQuery,
  useGetUserQuizSessionSettingsQuery,
  useQuizTimeUsersQuery,
  UserQuizSessionSettingsUserStatus,
} from '@/graphql';
import { useStyles } from './styles';
import { Participant } from '@/type';
import { useUser } from '@/contexts/user-context';
import { Skeleton } from '@material-ui/lab';
import QuizMemberItem from './member-item';
import { useApolloClient } from '@apollo/client';
import refetchQueries from 'refetch-queries';
import { QUIZ_TIME_DETAILS } from '@/apollo/queries';
import { QUIZTIME_MAX_DUEL_COUNT } from '@/utils/constants';

interface QuizMembersListProps {
  quizSessionId: string;
  navBtnActiveId?: number;
  getNumberOfMembers?: React.Dispatch<React.SetStateAction<number>>;
  getIsDuelsDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  hideMagnifier?: boolean;
  searchStr?: string;
}

const QuizMembersList: React.FC<QuizMembersListProps> = ({
  quizSessionId,
  navBtnActiveId,
  hideMagnifier,
  searchStr,
  getNumberOfMembers,
  getIsDuelsDisabled,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const elemFromVirtualDomRef = useRef<HTMLElement>(null);
  const [members, setMembers] = useState<Participant[]>();
  const { data, loading } = useQuizTimeUsersQuery({
    variables: { quizSessionId },
    fetchPolicy: 'cache-and-network',
  });
  const { data: userQuizSettingsData } = useGetUserQuizSessionSettingsQuery({
    variables: { quizSessionId },
  });
  const { data: activeQuizDuelsData } = useGetActiveQuizDuelsQuery({
    variables: { quizSessionId },
  });
  const [isUserAvailableForDuel, setUserAvailableForDuel] = useState(false);
  const client = useApolloClient();
  const cache = useApolloClient();

  const quizStatus: string = cache.readQuery({
    query: QUIZ_TIME_DETAILS,
    variables: { quizSessionId },
  })?.quizTimeSession?.status;

  useEffect(() => {
    if (quizStatus && quizStatus === QuizTimeSessionStatus.InProgress) {
      refetchQueries(client, [
        {
          query: QuizTimeUsersDocument,
          variables: { quizSessionId },
        },
      ]);
    }
  }, [client, quizSessionId, quizStatus]);

  const { user } = useUser();
  const isDuelsDisabledbyStatusOrQuantity = useMemo(() => {
    return (
      (activeQuizDuelsData?.activeQuizDuels?.length || 0) >=
        QUIZTIME_MAX_DUEL_COUNT ||
      (userQuizSettingsData?.userQuizSessionSettings?.userStatus !==
        UserQuizSessionSettingsUserStatus.InProgress &&
        userQuizSettingsData?.userQuizSessionSettings?.userStatus !==
          UserQuizSessionSettingsUserStatus.Paused)
    );
  }, [userQuizSettingsData, activeQuizDuelsData]);

  useEffect(() => {
    if (data?.quizSessionMembers) {
      const copyQuizMembers = [...data.quizSessionMembers];
      let isDuelsDisabled = true;
      let isUserDuelsDisabled = false;
      let membersList: Participant[] = copyQuizMembers
        .map((it, idx) => {
          const member: Participant = {
            id: it?.id,
            userId: it?.userData?.user?.id || '',
            username: it?.userData?.user?.username || '',
            avatar: it?.userData?.user?.avatar || '',
            score: it?.userData?.points || 0,
            rank: it?.userData?.rank || 0,
            isAvailableForDuel: !!it?.isAvailableForQuizDuel,
          };
          if (
            it?.isAvailableForQuizDuel === true &&
            member.userId !== user?.id
          ) {
            isDuelsDisabled = false;
          }
          if (member.userId === user?.id) {
            isUserDuelsDisabled = false;
          }
          return member;
        })
        .sort((a, b) =>
          navBtnActiveId === 2
            ? a.rank - b.rank
            : a.username.localeCompare(b.username)
        );
      if (searchStr) {
        membersList = membersList?.filter((member) =>
          member?.username?.toLowerCase()?.includes(searchStr.toLowerCase())
        );
      }
      if (navBtnActiveId === 2 && membersList.length > 0) {
        let prevRank = 0;
        membersList?.map((it) => {
          it.joinedRank = prevRank === it.rank;
          prevRank = it.rank;
          return it;
        });
      }
      getIsDuelsDisabled &&
        getIsDuelsDisabled(
          isDuelsDisabledbyStatusOrQuantity ||
            isDuelsDisabled ||
            isUserDuelsDisabled
        );
      setUserAvailableForDuel(
        !isDuelsDisabledbyStatusOrQuantity && !isUserDuelsDisabled
      );
      setMembers(membersList);
      getNumberOfMembers && getNumberOfMembers(membersList?.length);
    }
  }, [
    data,
    searchStr,
    getNumberOfMembers,
    getIsDuelsDisabled,
    user,
    navBtnActiveId,
    isDuelsDisabledbyStatusOrQuantity,
  ]);

  const [isMemberSticky, setMemberSticky] = useState(false);
  useLayoutEffect(() => {
    if (navBtnActiveId === 2) {
      const elem = document?.getElementById('scrollContainer');
      const h = window?.innerHeight;
      const handleScroll = () => {
        const elemFromVirtualDom = elemFromVirtualDomRef.current;
        if (!elemFromVirtualDom) return;
        const myMemberElem = elem?.querySelector('#myMemberRanglist');
        const myMemberPrevElem = myMemberElem?.previousElementSibling;
        const offset =
          myMemberPrevElem &&
          myMemberPrevElem?.getBoundingClientRect().bottom +
            myMemberPrevElem?.clientHeight;
        if (offset && offset > h + 10) {
          myMemberElem?.classList.add('sticky-member-ranglist');
          setMemberSticky(true);
        } else {
          myMemberElem?.classList.remove('sticky-member-ranglist');
          setMemberSticky(false);
        }
      };
      if (elem) elem.scrollTop = 0;
      const timer = setTimeout(() => {
        handleScroll();
      }, 100);
      elem?.addEventListener('scroll', debounce(handleScroll, 60), true);
      return () => {
        elem?.removeEventListener('scroll', handleScroll);
        clearTimeout(timer);
      };
    }
  }, [navBtnActiveId]);

  if (loading && !data)
    return (
      <Paper>
        <Box py={5} px={6}>
          {[...Array(10)].map((i, idx) => (
            <Skeleton key={idx} height={30} />
          ))}
        </Box>
      </Paper>
    );

  return (
    <Paper elevation={0} ref={elemFromVirtualDomRef}>
      {navBtnActiveId !== 2 && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={5}
          px={6}
        >
          <Typography variant="overline">
            {members?.length} {t('classrooms.attendees')}
          </Typography>
          {!hideMagnifier && (
            <IconButton
              color="primary"
              component={RouterLink}
              size="small"
              to={`/quiztime/${quizSessionId}/search-members`}
            >
              <Search htmlColor="#dadada" />
            </IconButton>
          )}
        </Box>
      )}

      <List className={classes.memberList} component="div">
        {members?.map((member, i) => (
          <QuizMemberItem
            key={member?.id}
            id={member?.id}
            userId={member?.userId}
            username={member?.username}
            avatar={member?.avatar}
            score={member?.score}
            isAvailableForDuel={
              isUserAvailableForDuel && member?.isAvailableForDuel
            }
            rank={member.rank}
            joinedRank={member.joinedRank}
            isRanking={navBtnActiveId === 2}
            isMemberSticky={isMemberSticky}
            divider={i !== (members?.length || 1) - 1}
            sessionId={quizSessionId}
          />
        ))}
      </List>
      {searchStr && !members?.length && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="35vh"
        >
          <Typography variant="body2">{t('common.no-found')}</Typography>
        </Box>
      )}
    </Paper>
  );
};

export default QuizMembersList;
