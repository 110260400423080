import React from 'react';
import { some, sortBy } from 'lodash';
import { Box } from '@material-ui/core';
import { useOrganizerClassroomsQuery } from '@/graphql';
import Loading from '@/components/loading';
import { useUser } from '@/contexts/user-context';
import ClassroomCard from '../../../card';
import { MAIN_ORGANIZER_NAME } from '@/utils/constants';
interface OrgRoomsTabProps {
  organizerId: string;
}

const OrgRoomsTab: React.FC<OrgRoomsTabProps> = ({ organizerId }) => {
  const { user } = useUser();
  const { data, loading } = useOrganizerClassroomsQuery({
    variables: { organizerId },
  });

  return loading && !data ? (
    <Box p={4} height="calc(100vh - 252px)">
      <Loading />
    </Box>
  ) : (
    <Box p={2}>
      {data?.organizer?.classroomSet?.map((it) => {
        const newestSession = sortBy(it.classRoomLearningSession, [
          'finishDate',
        ])[0];
        return (
          newestSession && (
            <ClassroomCard
              key={it.id}
              id={newestSession.id}
              link={`/classroom${
                some(user?.classRoomLearningSession, { id: newestSession.id })
                  ? ''
                  : '-details'
              }/${newestSession.id}`}
              title={it.title}
              organizerName={data?.organizer?.name}
              startDate={newestSession.startDate}
              finishDate={newestSession.finishDate}
              classRoomImage={
                (data.organizer?.name === MAIN_ORGANIZER_NAME &&
                  it.backgroundImage) ||
                it?.classRoomImage
              }
              specialityGroupIcon={newestSession?.specialityGroupIcon}
              classRoomType={it?.enrollmentType}
              // averageRate={it?.aggregatedRate?.averageRate}
              // feedbackCount={it?.aggregatedRate?.feedbackCount}
              certificate={it?.certificate?.points}
            />
          )
        );
      })}
    </Box>
  );
};

export default OrgRoomsTab;
