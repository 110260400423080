import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useApolloClient } from '@apollo/client';
import { isNumber, shuffle } from 'lodash';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import {
  QuestionType,
  useGetLekSessionQuery,
  UserLekDataStatus,
  useSetUserLekAnswerMutation,
  useUserLekFinishMutation,
  useUserLekStartMutation,
} from '@/graphql';
import Loading from '@/components/loading';
import { useCountdown } from '@/hooks/useCountdown';
import QuestionImage from '@/components/question-image';
import ChooseList from '@/components/choose-list';
import { Skeleton } from '@material-ui/lab';
import AppButton from '@/components/app-button';
import { GET_LEK_SESSION } from '@/apollo/queries';
import { useStyles } from './styles';

type ClassRoomLekParams = {
  sessionId: string;
  lekSessionId: string;
};

interface LastChoice {
  stepIdx: number;
  answerId: string;
}

const ClassRoomLek = () => {
  const { cache } = useApolloClient();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const { sessionId, lekSessionId } = useParams<ClassRoomLekParams>();

  const [choosedAnswerId, setAnswerId] = useState<string | null>(null);
  const [stepIdx, setStepIdx] = useState<number | null>(null);
  const [lastChoice, setLastChoice] = useState<LastChoice | null>(null);

  const [questionsWithMixedAnswers, setQuestionsWithMixedAnswers] =
    useState<QuestionType[] | null | undefined>(null);
  const unblockRef = useRef<any>();

  useEffect(() => {
    unblockRef.current = history.block();
  }, [history]);

  const { data, loading } = useGetLekSessionQuery({
    variables: {
      lekSessionId,
      sessionId,
    },
    onCompleted: (res) => {
      if (res?.lekSession?.lek.questions.length) {
        // copy full obj to make it writable
        const questions: [QuestionType] = JSON.parse(
          JSON.stringify(res.lekSession?.lek.questions)
        );
        setQuestionsWithMixedAnswers(
          questions.map((it: QuestionType) => {
            it.answers = shuffle(it.answers);
            return it;
          })
        );
      }
    },
  });

  const [lekStart, { data: lekStartData, loading: lekStartLoading }] =
    useUserLekStartMutation();

  const userLekData = useMemo(
    () =>
      data?.userLekData?.status === UserLekDataStatus.InProgress
        ? data?.userLekData
        : lekStartData?.lekStart?.userLekData,
    [lekStartData, data]
  );

  useEffect(() => {
    if (
      (userLekData?.status !== UserLekDataStatus.InProgress &&
        !lekStartLoading) ||
      (!userLekData && !lekStartLoading && !loading)
    ) {
      try {
        const timestamp = new Date().toISOString();
        const promise = lekStart({
          variables: {
            lekSessionId,
            startTime: timestamp,
          },
        });
        promise.then((res) => {
          cache.modify({
            id: `UserLEKDatasType:${lekSessionId}`,
            fields: {
              userLekData() {
                return res.data?.lekStart?.userLekData;
              },
            },
          });
        });
      } catch (error) {
        console.error('LEK_START', error);
      }
    }
  }, [userLekData, lekStartLoading, loading, lekSessionId, lekStart, cache]);

  const [setUserLekAnswer, { loading: setAnswerLoading }] =
    useSetUserLekAnswerMutation();

  useEffect(() => {
    const answersLength = userLekData?.userLekQuestionAnswerData.length;
    if (!isNumber(stepIdx) && userLekData) setStepIdx(answersLength || 0);
  }, [userLekData, history, sessionId, lekSessionId, stepIdx]);

  const currentQ = useMemo(
    () =>
      !!questionsWithMixedAnswers?.length
        ? questionsWithMixedAnswers[stepIdx || 0]
        : undefined,
    [stepIdx, questionsWithMixedAnswers]
  );

  const answerId = useMemo(
    () =>
      choosedAnswerId ||
      userLekData?.userLekQuestionAnswerData.find(
        (it) => it.question.id === currentQ?.id
      )?.userAnswer.id ||
      (lastChoice?.stepIdx === stepIdx && lastChoice.answerId),
    [userLekData, currentQ, choosedAnswerId, lastChoice, stepIdx]
  );

  const options = useMemo(() => {
    if (!currentQ?.answers)
      return [...Array(4)].map((_, i) => ({
        value: `skeletorn-${i}`,
        label: <Skeleton />,
      }));
    return currentQ?.answers.map((it) => ({
      value: String(it.id),
      label: it.value,
    }));
  }, [currentQ]);

  const handleSetAnswer = useCallback(async () => {
    if (!isNumber(stepIdx)) return;
    if (
      userLekData?.userLekQuestionAnswerData[stepIdx]?.userAnswer?.id ===
      answerId
    ) {
      setStepIdx(stepIdx + 1);
      if (stepIdx + 1 > (lastChoice?.stepIdx || 0)) setLastChoice(null);
      return false;
    }

    try {
      if (currentQ?.id) {
        const res = await setUserLekAnswer({
          variables: {
            answerId,
            lekSessionId,
            questionId: currentQ.id,
            answerTime: new Date().toISOString(),
          },
        });

        let userLekQuestionAnswerDataCopy = userLekData
          ?.userLekQuestionAnswerData?.length
          ? [...userLekData?.userLekQuestionAnswerData]
          : [];

        userLekQuestionAnswerDataCopy = userLekQuestionAnswerDataCopy.filter(
          (it) => it.question.id !== currentQ.id
        );

        cache.writeQuery({
          query: GET_LEK_SESSION,
          variables: { lekSessionId: data?.lekSession?.id, sessionId },
          data: {
            lekSession: data?.lekSession,
            userLekData: {
              ...userLekData,
              userLekQuestionAnswerData: [
                ...userLekQuestionAnswerDataCopy,
                {
                  __typename: 'UserLEKQuestionAnswerDataType',
                  id: res.data?.setLekAnswer?.userLekQuestionAnswerData?.id,
                  question: {
                    __typename: 'QuestionType',
                    id: currentQ.id,
                  },
                  userAnswer: {
                    __typename: 'AnswersType',
                    id: answerId,
                  },
                },
              ],
            },
          },
        });

        const userLekDataStatus =
          res.data?.setLekAnswer?.userLekQuestionAnswerData?.userLekData
            ?.status;
        if (userLekDataStatus === UserLekDataStatus.Finished) {
          cache.modify({
            id: `UserLEKDataType:${userLekData?.id}`,
            fields: {
              status() {
                return userLekDataStatus;
              },
            },
          });
          if (unblockRef.current) unblockRef.current();
          history.replace(`/classroom/${sessionId}/lek-results`);
        }
        if (isNumber(stepIdx)) setStepIdx(stepIdx + 1);
      }
    } catch (error) {
      console.error('SET_LEK_ANSWER', error);
    } finally {
      setAnswerId(null);
    }
  }, [
    answerId,
    lekSessionId,
    currentQ,
    setUserLekAnswer,
    setStepIdx,
    stepIdx,
    cache,
    userLekData,
    history,
    sessionId,
    data,
    lastChoice,
  ]);

  const handleGoBack = () => {
    if (stepIdx && choosedAnswerId) setLastChoice({ stepIdx, answerId });
    setAnswerId(null);
    if (isNumber(stepIdx)) setStepIdx(stepIdx - 1);
  };

  return loading && !data?.lekSession ? (
    <Box className={classes.loadingWrap}>
      <Loading />
    </Box>
  ) : (
    <>
      <header className={classes.header}>
        <Typography color="inherit" variant="h6">
          LEK{' '}
          <span className={classes.headerQCount}>{`${(stepIdx || 0) + 1} / ${
            data?.lekSession?.lek.questionCount
          }`}</span>
        </Typography>
        {userLekData?.created &&
          data?.lekSession?.lek.answerTimeDuration &&
          userLekData?.status === UserLekDataStatus.InProgress && (
            <TimeLeft
              userLekDataId={userLekData?.id}
              created={userLekData?.created}
              answerTimeDuration={data?.lekSession?.lek.answerTimeDuration + 1}
              unblockRef={unblockRef}
            />
          )}
      </header>
      <Paper elevation={0}>
        <Box className={classes.content}>
          <Box flexGrow={1}>
            <Box paddingBottom={3}>
              <Typography variant="subtitle1">{currentQ?.value}</Typography>
            </Box>
            <QuestionImage
              src={currentQ?.questionImage ?? undefined}
              alt={currentQ?.value}
            />
            <ChooseList
              data-cy-answers
              options={options}
              value={answerId}
              onChange={setAnswerId}
            />
          </Box>
          <Box
            display="grid"
            gridTemplateColumns={stepIdx !== 0 ? '1fr 1fr' : '1fr'}
            gridColumnGap={16}
            mt={6}
          >
            {stepIdx !== 0 && stepIdx && (
              <Button
                data-cy-submit-answer-next
                color="primary"
                variant="outlined"
                onClick={handleGoBack}
              >
                {t('common.back')}
              </Button>
            )}
            <AppButton
              disabled={!answerId}
              data-cy-submit-answer-next
              color="primary"
              variant="contained"
              onClick={handleSetAnswer}
              loading={setAnswerLoading}
            >
              {data?.lekSession?.lek.questionCount === (stepIdx || 0) + 1
                ? t('common.end')
                : t('common.next')}
            </AppButton>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default ClassRoomLek;

type TimeLeftProps = {
  userLekDataId: string;
  created: string;
  answerTimeDuration: number | undefined;
  unblockRef: any;
};
const TimeLeft: React.FC<TimeLeftProps> = ({
  userLekDataId,
  created,
  answerTimeDuration,
  unblockRef,
}) => {
  const history = useHistory();
  const { cache } = useApolloClient();
  const { sessionId, lekSessionId } = useParams<ClassRoomLekParams>();
  const ref = useRef(false);

  const [lekFinish] = useUserLekFinishMutation();

  const duration = useMemo(() => {
    let timeUsed = +DateTime.local().minus(+DateTime.fromISO(created)) / 1000;
    timeUsed = timeUsed < 0 ? 0 : timeUsed;
    return (answerTimeDuration || 0) - timeUsed;
  }, [created, answerTimeDuration]);
  const { timeLeft, timeLeftInMillis } = useCountdown(duration * 1000, true);

  const finish = useCallback(async () => {
    try {
      ref.current = true;
      const res = await lekFinish({
        variables: {
          lekSessionId,
          userLekDataId,
        },
      });
      const userLekData = res.data?.lekFinish?.userLekData;
      cache.modify({
        id: `UserLEKDataType:${userLekDataId}`,
        fields: {
          status() {
            return UserLekDataStatus.Finished;
          },
          elapsedTime() {
            return userLekData?.elapsedTime;
          },
          isPassed() {
            return userLekData?.isPassed;
          },
          correctAnswerCount() {
            return userLekData?.correctAnswerCount;
          },
        },
      });
    } catch (error) {
      console.error('LEK_FINISH', error);
    } finally {
      unblockRef?.current();
      history.replace(`/classroom/${sessionId}/lek-results`);
    }
  }, [
    cache,
    history,
    lekFinish,
    lekSessionId,
    sessionId,
    unblockRef,
    userLekDataId,
  ]);

  useEffect(() => {
    if (!ref.current && isNumber(timeLeftInMillis) && timeLeftInMillis < 0) {
      finish();
    }
  }, [timeLeftInMillis, finish]);

  return (
    <Typography color="inherit" variant="h6" align="right">
      {timeLeftInMillis > 0 && timeLeft}
    </Typography>
  );
};
