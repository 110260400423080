import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useSpecialityGroupListQuery } from '@/graphql';
import EmptyClassrooms from '../../../components/empty-classrooms';
import Loading from '@/components/loading';
import MainLayout from '../../../components/main-layout';
import SpecialityGroupCard from '../../speciality-group-card';
import { useStyles } from './styles';
import { ClassroomVariantsEnum } from '@/type';

const SpecialitiesList: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { data, loading } = useSpecialityGroupListQuery({
    fetchPolicy: 'cache-and-network',
  });

  const cardLoading = useMemo(() => <Loading />, []);

  return (
    <MainLayout
      title={t('networks.title')}
      toolbar={
        <IconButton
          color="primary"
          component={RouterLink}
          size="small"
          to={`/biblio/search-sessions`}
        >
          <Search htmlColor="#fff" />
        </IconButton>
      }
    >
      <Box py={1} px={2}>
        {loading && !data
          ? cardLoading
          : data?.userSpecialityGroupList?.map(
              (it) =>
                it && (
                  <SpecialityGroupCard
                    key={it.specialityGroup?.id}
                    id={it.specialityGroup?.id}
                    title={it.specialityGroup?.name}
                    image={it.specialityGroup?.icon}
                    count={it.classRoomSessionCount}
                  />
                )
            )}
      </Box>
      {!data?.userSpecialityGroupList?.length && (
        <Box className={classes.emptyWrap}>
          <EmptyClassrooms variant={ClassroomVariantsEnum.Kapitel} />
        </Box>
      )}
    </MainLayout>
  );
};

export default SpecialitiesList;
