import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';

import { useAuth } from '@/contexts/auth-context';
import { useSignUp } from '@/contexts/sign-up-context';
import { useSignUpMutation } from '@/graphql';
import Loading from '@/components/loading';
import { ProfessionTypesEnum } from '@/type';

const Completed = () => {
  const history = useHistory();
  const { t } = useTranslation();
  // professionType added here to execulde this value from singUpValues
  const { update, clean, professionType, ...singUpValues } = useSignUp();
  const { signIn, signOut } = useAuth();

  const [registration, { loading, error }] = useSignUpMutation();

  const params = new URLSearchParams(history.location.search);
  const deepLinkHash = params.get('hash');
  const sharingSenderId = params.get('sharingSenderId');

  useEffect(() => {
    const signUp = async () => {
      try {
        signOut(true);
        const cleanedSingUpValues = JSON.parse(JSON.stringify(singUpValues));
        if (deepLinkHash) {
          cleanedSingUpValues.deepLinkHash = deepLinkHash;
          cleanedSingUpValues.sharingSenderId = sharingSenderId;
        }
        if (sharingSenderId) {
          cleanedSingUpValues.sharingSenderId = sharingSenderId;
        }
        const { data } = await registration({
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          variables: cleanedSingUpValues as any,
        });

        if (data?.userRegistration?.token) {
          datadogRum.addAction('sign_up_success', {
            timestamp: Date.now(),
          });
          const user = data?.userRegistration?.user;
          if (user)
            datadogRum.setUser({
              id: user?.id,
              name: user.username!,
              email: user.email!,
              plan: user.isPremium,
              profession: user.profession?.title,
              speciality: user.speciality?.title,
              university: user.university?.title,
              gender: user.gender,
              dateOfBirth: user.dateOfBirth,
            });
          // clear all registration Data
          sessionStorage.clear();
          signIn(data.userRegistration.token);

          const professionType = user?.profession?.type?.type;
          const isDoctor = professionType === ProfessionTypesEnum.Doctor;
          const isStudent = professionType === ProfessionTypesEnum.Student;
          const path = `/sign-up-confirm-email${isDoctor ? "-doctors" : isStudent ? "-students" : ""}?email=${encodeURIComponent(user?.email || "")}&hash=${encodeURIComponent(deepLinkHash || "")}`;

          history.push(path);
        }
      } catch (error) {
        console.error('REGISTRATION', error);
        datadogRum.addAction('sign_up_failure', {
          errorMessage: error instanceof Error ? error.message : String(error),
          timestamp: Date.now(),
        });
      } finally {
        clean();
      }
    };

    signUp();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Box my={5} mx={8}>
      <Typography align="center" variant="body2">
        {error ? error.message : t('fallback.message.info')}
      </Typography>
    </Box>
  );
};

export default Completed;
