import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  useClassRoomsAvailableBySpecialityGroupQuery,
  useSpecialityGroupQuery,
} from '@/graphql';
import { Box } from '@material-ui/core';
import EmptyClassrooms from '../../../components/empty-classrooms';
import ClassroomCard from '../../card';
import Loading from '@/components/loading';
import StackLayout from '../../../components/stack-layout';
import { useStyles } from './styles';
import { MAIN_ORGANIZER_NAME } from '@/utils/constants';
import { ClassroomVariantsEnum } from '@/type';

type SessionParams = {
  specialityGroupId: string;
};

const NetworkSessionsBySpec: React.FC = ({ children }) => {
  const classes = useStyles();
  const { specialityGroupId } = useParams<SessionParams>();

  const { data, loading } = useClassRoomsAvailableBySpecialityGroupQuery({
    variables: {
      specialityGroupId,
    },
    fetchPolicy: 'cache-and-network',
  });
  const { data: dataSpecialityGroup } = useSpecialityGroupQuery({
    variables: {
      specialityGroupId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const cardLoading = useMemo(() => <Loading />, []);

  return (
    <StackLayout title={dataSpecialityGroup?.specialityGroup?.name || ''}>
      <div className={classes.root}>
        {loading && !data ? (
          cardLoading
        ) : (
          <>
            {data?.availableSessionsBySpecialityGroup?.map(
              (it) =>
                it && (
                  <ClassroomCard
                    key={it.id}
                    id={it.id}
                    link={`/classroom-details/${it.id}/${specialityGroupId}`}
                    title={it.classRoom.title}
                    organizerName={it.classRoom?.organizer?.name}
                    startDate={it?.startDate}
                    finishDate={it?.finishDate}
                    classRoomImage={
                      (it.classRoom?.organizer?.name === MAIN_ORGANIZER_NAME &&
                        it.classRoom?.backgroundImage) ||
                      it.classRoom?.classRoomImage
                    }
                    specialityGroupIcon={
                      it.classRoom?.organizer?.name === MAIN_ORGANIZER_NAME
                        ? dataSpecialityGroup?.specialityGroup?.icon
                        : null
                    }
                    classRoomType={it.classRoom?.enrollmentType}
                    // averageRate={it.classRoom?.aggregatedRate?.averageRate}
                    // feedbackCount={
                    //   it.classRoom?.aggregatedRate?.feedbackCount
                    // }
                    certificate={it.classRoom?.certificate?.points}
                    isForPremiumOnly={it?.isForPremiumOnly}
                    duration={it?.duration}
                  />
                )
            )}
            {!data?.availableSessionsBySpecialityGroup?.length && (
              <Box className={classes.emptyWrap}>
                <EmptyClassrooms variant={ClassroomVariantsEnum.Laufend} />
              </Box>
            )}
          </>
        )}
        {children}
      </div>
    </StackLayout>
  );
};

export default NetworkSessionsBySpec;
