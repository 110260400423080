import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import { Box, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { useClassroomLecturesQuery, useClassRoomSessionQuery } from '@/graphql';
import LectureItem from './lecture-item';
import { ClassroomTypes } from '@/type';

type LecturesProps = {
  sessionId: string;
  userSettingsId?: string | null | undefined;
};

const Lectures: React.FC<LecturesProps> = ({ sessionId, userSettingsId }) => {
  const { t } = useTranslation();

  const [expandedNumber, setExpanded] = React.useState<number | false>(1);
  const { data, loading } = useClassroomLecturesQuery({
    variables: { learningSessionId: sessionId },
    fetchPolicy: 'cache-and-network',
  });

  const classRoomLectures = useMemo(
    () => sortBy(data?.classRoomLectures, ['lectureNumber']),
    [data]
  );
  const { data: sessionData } = useClassRoomSessionQuery({
    variables: { sessionId },
    fetchPolicy: 'cache-and-network',
  });

  const handleChange =
    (numb: number) =>
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      event: React.ChangeEvent<{}>,
      isExpanded: boolean
    ) => {
      setExpanded(isExpanded ? numb : false);
    };

  return loading && !classRoomLectures?.length ? (
    <Paper>
      <Box py={5} px={6}>
        {[...Array(4)].map((i, idx) => (
          <Skeleton key={idx} height={30} />
        ))}
      </Box>
    </Paper>
  ) : classRoomLectures?.length ? (
    <Paper elevation={0}>
      <Box py={3}>
        <Box px={6} py={3}>
          <Typography variant="h2">{t('class-room.lectures')}</Typography>
        </Box>
        <Box>
          {classRoomLectures?.map((it, idx) => {
            return (
              it && (
                <LectureItem
                  key={it.id}
                  isMember={!!userSettingsId}
                  number={idx + 1}
                  title={it.lecture?.title}
                  description={it.lecture?.description}
                  link={it.lecture?.lectureLink}
                  lectors={it.lecture?.lectors}
                  expanded={expandedNumber === idx + 1}
                  handleChange={handleChange}
                  isNetworkCR={
                    sessionData?.classRoomLearningSession?.classRoom
                      .enrollmentType === ClassroomTypes.NETWORK
                  }
                />
              )
            );
          })}
        </Box>
      </Box>
    </Paper>
  ) : (
    <></>
  );
};

export default Lectures;
