import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  List,
  ListItem,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import {
  useDtWeeklyProgressQuery,
  useUserAchievementsRangQuery,
} from '@/graphql';

import Item from './item';
import StackLayout from '../../components/stack-layout';
import GoalsInfo from '../goals-info';
import DtUsersIcon from '@/components/icons/dt-users';
import { LockOutlined } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { DT_LEARNING_YEAR_IN_DAYS } from '@/utils/constants';

const useStyles = makeStyles((theme) => ({
  usersIconBox: {
    position: 'absolute',
    right: 24,
    zIndex: 0,
    background: 'rgba(255, 255, 255, 0.25)',
    filter: 'blur(5px)',
    '& img': {
      height: 36,
    },
  },
  moreBtnDisabled: {
    height: 32,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#DADADA',
    background: 'rgba(255, 255, 255, 0.85)',
    border: '1px solid #ECF0F1',
    borderRadius: 4,
    zIndex: 1,
  },
}));

const DtAchievements: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, loading } = useUserAchievementsRangQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: dtWeeklyProgress } = useDtWeeklyProgressQuery();
  const currentAchievement = useMemo(
    () => dtWeeklyProgress?.me?.dailytipper?.currentAchievement?.learningDays,
    [dtWeeklyProgress]
  );

  const isAchievementsListFull = useMemo(() => {
    return (data?.achievementsRang || []).some(
      (it) => it?.achievement?.learningDays === DT_LEARNING_YEAR_IN_DAYS
    );
  }, [data]);

  const achievementsList = useMemo(() => {
    const achievements = (data?.achievementsRang || []).filter(
      (it) =>
        it &&
        it.achievement &&
        it.achievement.learningDays > 0 &&
        it.usersWithAchievement
    );

    return !loading ? (
      achievements.map((it, idx) =>
        it?.achievement ? (
          <Item
            key={`achievement-${it.achievement.learningDays}`}
            data-cy-achievement-item
            learningDays={it.achievement.learningDays}
            currentAchievement={currentAchievement}
            users={it.usersWithAchievement?.map((aUser) => ({
              avatar: aUser?.user?.avatar ?? undefined,
              username: aUser?.user?.username ?? undefined,
            }))}
            countUsers={it.usersWithAchievementCount}
            divider={
              isAchievementsListFull && achievements.length - 1 === idx
                ? false
                : true
            }
          />
        ) : (
          <></>
        )
      )
    ) : (
      <>
        <Item learningDays={7} />
        <Item learningDays={15} />
        <Item learningDays={30} />
        <Item learningDays={60} />
        <Item learningDays={120} />
      </>
    );
  }, [data, loading, currentAchievement, isAchievementsListFull]);

  return (
    <StackLayout title={t('daily-tipper.achievements')} back="/daily-tipper">
      <Paper elevation={0}>
        <GoalsInfo hideLinkArrow />
      </Paper>
      <Box padding={2}>
        <Paper elevation={0}>
          {loading && !data ? (
            <Box py={5} px={6}>
              {[...Array(17)].map((i, idx) => (
                <Skeleton key={idx} height={30} />
              ))}
            </Box>
          ) : (
            <Box>
              <Box paddingTop={5} paddingX={6}>
                <Typography variant="overline">
                  {t('daily-tipper.achievements-list')}
                </Typography>
              </Box>
              <List>
                {achievementsList}
                {!isAchievementsListFull && (
                  <ListItem
                    style={{
                      paddingTop: 14,
                      paddingBottom: 14,
                      marginBottom: 20,
                    }}
                  >
                    <Box className={classes.moreBtnDisabled}>
                      <LockOutlined
                        color="inherit"
                        style={{ marginRight: 12 }}
                      />
                      <Typography
                        variant="subtitle2"
                        style={{
                          color: '#929DA8',
                        }}
                      >
                        {t('daily-tipper.locked-milestone')}
                      </Typography>
                    </Box>
                    <span className={classes.usersIconBox}>
                      <DtUsersIcon />
                    </span>
                  </ListItem>
                )}
              </List>
            </Box>
          )}
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default DtAchievements;
