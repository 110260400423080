import React from 'react';

interface PublicHeaderProps {
  text: string;
  subText?: string | null;
}

const HyperText: React.FC<PublicHeaderProps> = ({
  children,
  text,
  subText,
}) => (
  <>
    {text.split('{0}')[0]}
    {children}
    {text.split('{0}')[1]}
    {subText}
  </>
);

export default HyperText;
