import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import { Box } from '@material-ui/core';
import {
  useClassRoomsFinishedLazyQuery,
  useClassRoomsOngoingLazyQuery,
} from '@/graphql';
import { AppTab, AppTabs, TabPanel } from '@/components/tabs';
import EmptyClassrooms from '../../../components/empty-classrooms';
import MainLayout from '../../../components/main-layout';
import ClassroomCard from '../../card';
import Loading from '@/components/loading';
import { useTabsNav } from '@/hooks/useTabsNav';
import { ClassroomTypes, ClassroomVariantsEnum, TabNavIdsEnum } from '@/type';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import { useStyles } from './styles';
import {
  LS_KEY_TABINDEX,
  MAIN_ORGANIZER_NAME,
  SS_KEY_MEMO_CUR_TAB,
} from '@/utils/constants';

const Classrooms: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isUserPremium, premiumFinishDate } = useGetPremiumData();

  const [getOngoingCR, { loading, data }] = useClassRoomsOngoingLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [
    getFinishedCR,
    { loading: loadingFinishedCR, data: dataClassroomsFinished },
  ] = useClassRoomsFinishedLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const { value, handleChange } = useTabsNav(TabNavIdsEnum.CLASSROOMS);
  useEffect(() => {
    switch (value) {
      case 0:
        getOngoingCR();
        break;
      case 1:
        getFinishedCR();
        break;
    }
  }, [value, getOngoingCR, getFinishedCR]);

  const cardLoading = useMemo(() => <Loading />, []);

  useEffect(() => {
    return () => {
      if (sessionStorage.getItem(SS_KEY_MEMO_CUR_TAB) !== 'true')
        localStorage.setItem(
          `${TabNavIdsEnum.CLASSROOMS}${LS_KEY_TABINDEX}`,
          '0'
        );
      sessionStorage.removeItem(SS_KEY_MEMO_CUR_TAB);
    };
  }, []);

  return (
    <MainLayout title={t('class-room.title2')}>
      <div className={classes.root}>
        <AppTabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <AppTab
            data-cy-classrooms-caption-active
            label={t('classrooms.caption.active')}
          />
          <AppTab
            data-cy-classrooms-caption-finished
            label={t('classrooms.caption.finished')}
          />
        </AppTabs>

        <TabPanel value={value} index={0} styles={classes.tabPanel}>
          {loading && !data?.ongoingClassRooms?.length ? (
            cardLoading
          ) : (
            <>
              {data?.ongoingClassRooms?.map(
                (it) =>
                  it?.learningSession && (
                    <ClassroomCard
                      key={it.learningSession?.id}
                      id={it.learningSession.id}
                      link={`/classroom/${it.learningSession.id}`}
                      deepLinkHash={it.learningSession.deepLink}
                      title={it.learningSession.classRoom.title}
                      organizerName={
                        it.learningSession?.classRoom?.organizer?.name
                      }
                      startDate={
                        it.networkStartDate || it.learningSession?.startDate
                      }
                      finishDate={
                        it.networkStartDate
                          ? DateTime.fromISO(it.networkStartDate).plus({
                              days: (it.learningSession.duration || 1) - 1,
                            })
                          : it.learningSession?.finishDate
                      }
                      classRoomImage={
                        (it.learningSession?.classRoom?.organizer?.name ===
                          MAIN_ORGANIZER_NAME &&
                          it.learningSession?.classRoom?.backgroundImage) ||
                        it.learningSession?.classRoom?.classRoomImage
                      }
                      specialityGroupIcon={
                        it.learningSession?.classRoom?.organizer?.name ===
                        MAIN_ORGANIZER_NAME
                          ? it.specialityGroupIcon
                          : null
                      }
                      classRoomType={
                        it.learningSession?.classRoom?.enrollmentType
                      }
                      // averageRate={
                      //   it.learningSession.classRoom?.aggregatedRate
                      //     ?.averageRate
                      // }
                      // feedbackCount={
                      //   it.learningSession.classRoom?.aggregatedRate
                      //     ?.feedbackCount
                      // }
                      certificate={
                        it.learningSession?.classRoom?.certificate?.points
                      }
                      isForPremiumOnly={it.learningSession?.isForPremiumOnly}
                      indicator={!!it.isSessionActive}
                      correctAnswersGoal={it.correctAnswersGoal}
                      questionsAmount={it.questionsAmount}
                      correctAnswersAmount={it.correctAnswersAmount}
                      isOngoing
                    />
                  )
              )}
              {!data?.ongoingClassRooms?.length && (
                <Box className={classes.emptyWrap}>
                  <EmptyClassrooms variant={ClassroomVariantsEnum.Laufend} />
                </Box>
              )}
            </>
          )}
        </TabPanel>
        <TabPanel value={value} index={1} styles={classes.tabPanel}>
          {loadingFinishedCR &&
          !dataClassroomsFinished?.finishedClassRooms?.length
            ? cardLoading
            : dataClassroomsFinished?.finishedClassRooms?.map((it) => {
                const finishNetworkCR = DateTime.fromISO(
                  it?.networkStartDate || ''
                )
                  .plus({
                    days: (it?.learningSession?.duration || 1) - 1,
                  })
                  .toISO();
                return (
                  it?.learningSession && (
                    <ClassroomCard
                      key={it.userLearningSessionSettingsId}
                      id={it.learningSession.id}
                      link={`/classroom-finished/${it.learningSession.id}/${it.userLearningSessionSettingsId}/`}
                      title={it.learningSession.classRoom.title}
                      organizerName={
                        it.learningSession.classRoom?.organizer?.name
                      }
                      startDate={
                        it.learningSession.classRoom?.enrollmentType ===
                        ClassroomTypes.NETWORK
                          ? it?.networkStartDate
                          : it.learningSession?.startDate
                      }
                      finishDate={
                        it.learningSession.classRoom?.enrollmentType ===
                          ClassroomTypes.NETWORK && it?.networkStartDate
                          ? !isUserPremium &&
                            it.learningSession.isForPremiumOnly &&
                            (premiumFinishDate || it?.outViaSubscriptionDate) <
                              finishNetworkCR
                            ? premiumFinishDate || it?.outViaSubscriptionDate
                            : finishNetworkCR
                          : it.learningSession?.finishDate
                      }
                      classRoomImage={
                        (it.learningSession?.classRoom?.organizer?.name ===
                          MAIN_ORGANIZER_NAME &&
                          it.learningSession?.classRoom?.backgroundImage) ||
                        it.learningSession.classRoom?.classRoomImage
                      }
                      specialityGroupIcon={
                        it.learningSession?.classRoom?.organizer?.name ===
                        MAIN_ORGANIZER_NAME
                          ? it.specialityGroupIcon
                          : null
                      }
                      classRoomType={
                        it.learningSession.classRoom?.enrollmentType
                      }
                      // averageRate={
                      //   it.learningSession.classRoom?.aggregatedRate
                      //     ?.averageRate
                      // }
                      // feedbackCount={
                      //   it.learningSession.classRoom?.aggregatedRate
                      //     ?.feedbackCount
                      // }
                      certificate={
                        it.learningSession.classRoom?.certificate?.points
                      }
                      isForPremiumOnly={it.learningSession?.isForPremiumOnly}
                      correctAnswersGoal={it.correctAnswersGoal}
                      questionsAmount={it.questionsAmount}
                      correctAnswersAmount={it.correctAnswersAmount}
                    />
                  )
                );
              })}
          {!dataClassroomsFinished?.finishedClassRooms?.length && (
            <Box className={classes.emptyWrap}>
              <EmptyClassrooms variant={ClassroomVariantsEnum.Fertig} />
            </Box>
          )}
        </TabPanel>
        {children}
      </div>
    </MainLayout>
  );
};

export default Classrooms;
