import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';
import { Box, Paper } from '@material-ui/core';

import StackLayout from '@/screens/main/components/stack-layout';
import * as Form from '@/components/form';
import Loading from '@/components/loading';
import { useUserNetworkCodesQuery, useUserSubscriptionQuery } from '@/graphql';
import { CustomCodesType, SubscriptionStatus } from '@/type';
import { useUser } from '@/contexts/user-context';
import Fallback from '@/components/fallback';
import { useGetPremiumData } from '@/hooks/useGetPremiumData';
import FutureCodes from './components/future-codes';
import SubsStatusIncomplete from './components/subscription-incomplete';
import UserFreemiumBlock from './components/user-freemium-block';
import UserPremiumBlock from './components/user-premium-block';
import { useSendUserDataToNative } from './useUserDataToNative';

interface Form {
  firstName: string;
  lastName: string;
  username: string;
  gender: string;
  dateOfBirth: string;
  postcode: string;
}

const NetworkPersonal: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { user } = useUser();
  const { isUserPremium, currentCode, subscriptionType } = useGetPremiumData();
  useSendUserDataToNative();

  const { data, error, loading } = useUserNetworkCodesQuery();
  const { data: subsData } = useUserSubscriptionQuery();

  const futureCodes = useMemo(() => {
    return (
      data?.codes
        ?.filter((it): it is CustomCodesType => it !== null && it !== undefined)
        ?.filter((it) => DateTime.fromISO(it.startDate) > DateTime.local()) ||
      []
    );
  }, [data]);

  const isSubsStatusIncomplete =
    subsData?.me?.customer?.subscription?.status ===
      SubscriptionStatus.INCOMPLETE ||
    subsData?.me?.customer?.subscription?.upgradeStatus ===
      SubscriptionStatus.INCOMPLETE;

  const backLink = useMemo(() => {
    const historyState: any = history.location.state;
    if (historyState?.prevPage) return historyState.prevPage;
    else return undefined;
  }, [history]);

  if (!!error) {
    console.error('GET_NETWORK', error);
    return (
      <StackLayout title={t('my-profile.network.title')} back={backLink}>
        <Fallback />
      </StackLayout>
    );
  }

  if (loading && !data)
    return (
      <StackLayout title={t('my-profile.network.title')} back={backLink}>
        <Loading />
      </StackLayout>
    );

  return (
    <StackLayout title={t('my-profile.network.title')} back={backLink}>
      <Box
        clone
        boxSizing="border-box"
        paddingY={7}
        paddingX={4}
        position="relative"
        style={{ minHeight: '100%' }}
      >
        <Paper elevation={0}>
          <Form.InfoField label={t('fields.network.label')}>
            {user?.network?.title}
          </Form.InfoField>
          <UserFreemiumBlock
            isSubsStatusIncomplete={isSubsStatusIncomplete}
            isUserPremium={isUserPremium}
            currentCode={currentCode}
          />
          <UserPremiumBlock isSubsStatusIncomplete={isSubsStatusIncomplete} />
          <SubsStatusIncomplete
            isSubsStatusIncomplete={isSubsStatusIncomplete}
            subscriptionType={subscriptionType}
          />
          <br />
          <FutureCodes futureCodes={futureCodes} />
        </Paper>
      </Box>
    </StackLayout>
  );
};

export default NetworkPersonal;
