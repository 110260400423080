import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { useDtWeeklyProgressQuery } from '@/graphql';
import { InfoOutlined } from '@material-ui/icons';
import { useDailyTipperRulesFullScreenModal } from '@/hooks/useDailyTipperRulesFullScreenModal';
import { useUser } from '@/contexts/user-context';
import { InfoBox } from '@/components/info-box';
import LearningWeek from './learning-week';
import HexahedronIcon from '@/components/icons/hexahedron';
import MainLayout from '../components/main-layout';
import GoalsInfo from './goals-info';
import { Skeleton } from '@material-ui/lab';
import { getDtYearsColors } from '@/utils/helpers';
import { useStyles } from './styles';

const DailyTipper: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const classes = useStyles();
  const dtRulesFullScreenModal = useDailyTipperRulesFullScreenModal();

  const { data, loading } = useDtWeeklyProgressQuery({
    fetchPolicy: 'cache-and-network',
  });

  const dailytipper = useMemo(() => data?.me?.dailytipper, [data]);
  const nextAchievement = dailytipper?.nextAchievement?.learningDays ?? 0;
  const currentAchievement = dailytipper?.currentAchievement?.learningDays ?? 0;
  const progress = useMemo(() => {
    const current = dailytipper?.totalProgress ?? 0;
    return (
      ((current - currentAchievement) /
        (nextAchievement - currentAchievement)) *
      100
    );
  }, [dailytipper, currentAchievement, nextAchievement]);

  const progressText = useMemo(
    () => dailytipper?.totalProgress ?? 0,
    [dailytipper]
  );
  const learningYearNumber = useMemo(
    () => dailytipper?.yearAchievement?.yearNumber,
    [dailytipper]
  );
  const dtYearsColorsObj = useMemo(
    () => (learningYearNumber ? getDtYearsColors(learningYearNumber) : null),
    [learningYearNumber]
  );

  return (
    <MainLayout
      title={t('daily-tipper.title')}
      toolbar={
        <IconButton
          color="primary"
          size="small"
          onClick={() => dtRulesFullScreenModal.open()}
          data-dd-action-name="DT open rules modal"
        >
          <InfoOutlined htmlColor="#fff" />
        </IconButton>
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        style={{
          minHeight: '-webkit-fill-available',
        }}
      >
        <Box clone px={2} pt={2}>
          <Paper elevation={0}>
            {user?.settings?.dailyTipperMode === false && (
              <Box px={2} mb={2}>
                <InfoBox>{t('common.daily-tipper.dt-on-pause-info')}</InfoBox>
              </Box>
            )}
            {loading && !data ? (
              <Box py={2}>
                {[...Array(9)].map((i, idx) => (
                  <Skeleton key={idx} height={30} />
                ))}
              </Box>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                p={4}
                pl={6}
                py={4}
                ml={-2}
                style={{
                  background: '#E6EEFA',
                  borderRadius: '0px 104px 104px 0px',
                }}
              >
                <Box display="grid" gridRowGap={4} pr={6}>
                  <Typography variant="overline">
                    {t('daily-tipper.learning-run.title')}
                  </Typography>
                  <Typography variant="caption" style={{ color: '#879BB2' }}>
                    {t('daily-tipper.learning-run.info')}
                  </Typography>
                  <Box className={classes.learningYear}>
                    {dtYearsColorsObj && (
                      <Box
                        position="relative"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mr={2.5}
                      >
                        {!!learningYearNumber &&
                          [...Array(learningYearNumber)].map((it, idx) => (
                            <HexahedronIcon
                              key={idx}
                              colorPrimary={dtYearsColorsObj.color1}
                              colorSecondary={
                                idx === learningYearNumber - 1
                                  ? dtYearsColorsObj.color2
                                  : dtYearsColorsObj.color3
                              }
                              style={{
                                fontSize: 34,
                                marginLeft: idx > 0 ? -29.8 : 0,
                              }}
                            />
                          ))}
                        <span
                          className={classes.hexahedronNumber}
                          style={{
                            textShadow: `0px 0px 2px ${dtYearsColorsObj.color4}`,
                          }}
                        >
                          {learningYearNumber}
                        </span>
                      </Box>
                    )}
                    <Typography variant="overline">
                      {t('daily-tipper.learning-year')}
                    </Typography>
                  </Box>
                </Box>
                <Box position="relative" display="inline-flex">
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    size={108}
                    thickness={4}
                    className={classes.determinate}
                  />
                  <Box position="absolute">
                    <CircularProgress
                      variant="determinate"
                      size={108}
                      value={progress}
                      style={{ strokeLinecap: 'round' }}
                    />
                  </Box>
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      component="div"
                      color="primary"
                      style={{
                        fontSize: 28,
                        lineHeight: 1,
                        fontWeight: 700,
                        letterSpacing: '0.18px',
                        color: '#2B75B3',
                      }}
                    >
                      {progressText}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Paper>
        </Box>
        <GoalsInfo />
        <LearningWeek />
        {children}
      </Box>
    </MainLayout>
  );
};

export default DailyTipper;
