import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { AppTab, AppTabs, TabPanel } from '@/components/tabs';
import { useOrganizerQuery } from '@/graphql';
import OrgHeader from '../../org-header';
import { useStyles } from './styles';
import { Skeleton } from '@material-ui/lab';
import { Box, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import StackLayout from '../../../components/stack-layout';
import { MoreVert } from '@material-ui/icons';
import OrgOverview from '../../org-overview';
import OrgRoomsTab from './org-rooms-tab';
import DropdownMenu from '../../../components/dropdown-menu';
import { useTabsNav } from '@/hooks/useTabsNav';
import { TabNavIdsEnum } from '@/type';
import { HEIGHT_TOPBAR_PX, REPORT_EMAIL } from '@/utils/constants';

type ClassRoomParams = {
  organizerId: string;
};

const ClassroomOrganizer = () => {
  const { t } = useTranslation();
  const { organizerId } = useParams<ClassRoomParams>();
  const classes = useStyles();
  const { value, handleChange } = useTabsNav(TabNavIdsEnum.ORGANIZER);
  const [tabsHeight, setTabsHeight] = React.useState<number | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const { data, loading } = useOrganizerQuery({
    variables: { organizerId },
  });

  const dropMenuOptions = useMemo(
    () => [
      {
        id: '001',
        link: { pathname: `mailto:${REPORT_EMAIL}` },
        label: t('classrooms.report-profile'),
        targetBlank: true,
      },
    ],
    [t]
  );

  useEffect(() => {
    if (elementRef.current) {
      const maxHeight =
        window.innerHeight - elementRef.current?.offsetTop - HEIGHT_TOPBAR_PX;
      if (maxHeight) setTabsHeight(maxHeight);
    }
  }, [data]);

  return (
    <StackLayout
      title={t('classrooms.title-organizer')}
      toolbar={<DropdownMenu btn={<MoreVert />} options={dropMenuOptions} />}
    >
      <OrgHeader
        name={data?.organizer?.name || ''}
        image={data?.organizer?.logo}
        // averageRate={data?.organizer?.aggregatedRate?.averageRate}
        // feedbackCount={data?.organizer?.aggregatedRate?.feedbackCount}
        location={data?.organizer?.city?.title}
      />
      <div className={classes.content}>
        {loading && !data ? (
          <Paper>
            <Box py={5} px={6}>
              {[...Array(15)].map((i, idx) => (
                <Skeleton key={idx} height={30} />
              ))}
            </Box>
          </Paper>
        ) : (
          <>
            <AppTabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <AppTab
                data-cy-classrooms-caption-active
                label={t('common.overview')}
              />
              <AppTab
                data-cy-classrooms-caption-pending
                label={`${t('common.classrooms')} (${
                  data?.organizer?.classroomSet.length
                })`}
              />
            </AppTabs>
            <div
              ref={elementRef}
              style={{
                height: tabsHeight ? tabsHeight : 'auto',
                overflowY: 'auto',
              }}
            >
              <TabPanel value={value} index={0}>
                <OrgOverview
                  about={data?.organizer?.about}
                  website={data?.organizer?.linkToSite}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <OrgRoomsTab organizerId={data?.organizer?.id} />
              </TabPanel>
            </div>
          </>
        )}
      </div>
    </StackLayout>
  );
};

export default ClassroomOrganizer;
