import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import SmallLogo from '@/components/icons/small-logo';
import { useStyles } from './styles';

interface LayoutProps {
  title: string;
  page?: string;
  contentUnderHeader?: React.ReactElement;
}

const Layout: React.FC<LayoutProps> = ({ page, title, contentUnderHeader, children }) => {
  const classes = useStyles();

  return (
    <Box p={4} paddingBottom={8} className={classes.main}>
      <Box marginBottom={7}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Link to="/">
              <SmallLogo />
            </Link>
          </Grid>
          {!!page && (
            <Grid item>
              <Typography component="span" variant="body2">
                {page}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
      {!!contentUnderHeader && contentUnderHeader}
      <Box>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};

export default Layout;
